import React, { useEffect } from "react";
import loadable from "@loadable/component";
import { Outlet, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import localeRouteHelper from "i18n/routeHelper";

import "./DefaultLayout.scss";

const PageHeaderBar: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-PageHeaderBar"              */ "../../components/PageHeaderBar"
    )
);

const PageFooter: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-PageFooter"              */ "../../components/PageFooter"
    )
);

const PreFooterCTA: any = loadable(
  () =>
    import(
      /* webpackChunkName: "components-PreFooterCTA"              */ "../../components/PreFooterCTA"
    )
);

const MobileDownloadBanner: any = loadable(
  () =>
    import(
      /* webpackChunkName: "containers/Experiments/MobileDownloadBanner"              */ "../../containers/Experiments/MobileDownloadBanner"
    )
);

function DefaultLayout2(): JSX.Element {
  const location = useLocation();
  const intl = useIntl();

  const routeHelper = localeRouteHelper[intl.locale];

  useEffect(() => {
    if (
      typeof window !== undefined &&
      typeof (window as any).gtag === "function"
    ) {
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search
      });
    }
  }, [location]);

  // Specify the routes where you want to hide the PreFooterCTA
  const hidePreFooterCTARoutes = [routeHelper.peanutAtWork()];

  const shouldHidePreFooterCTA = hidePreFooterCTARoutes.includes(
    location.pathname
  );

  return (
    <div className="wrapper">
      <PageHeaderBar />

      <div style={{ minHeight: "100vh", padding: 0 }}>
        <Outlet />
      </div>

      <PreFooterCTA className={shouldHidePreFooterCTA ? "hidden" : ""} />

      <PageFooter />

      <MobileDownloadBanner />
    </div>
  );
}

export default DefaultLayout2;
